import React from 'react';
import styled from 'styled-components';
import StarsBg from 'images/bg.svg';
import {media} from 'global.config';

export default styled.div`
  background: url(${StarsBg}) no-repeat top center,
    linear-gradient(to bottom, #252a3b 0%, #15294b 40%, #1d2e42 80%);
  background-color: #1d2e42;
  background-size: auto;
  height: 100%;
`;
